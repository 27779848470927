.whatsapp-icon, .tg-icon {
  position: fixed;
  bottom: 77px;
  left: 55px;
  width: 58px;
  height: 58px;
  border-radius: 5px;
  background-color: #18a918;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 50000;
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;  
}

.whatsapp-icon:hover, .tg-icon:hover { 
    background-color: #4caf50;
    animation-name: unset;
    opacity: 0.8;
}

.tg-icon svg,
.whatsapp-icon svg {
  width: 40px;
  height: 40px;
}

.tg-icon svg {
  width: 45px;
  height: 45px;
  fill: #fff;
  border-radius: 5px;
}

.tg-icon {
    bottom: 160px;
    background: #03a9f4;
}

.tg-icon:hover {
    background: #03a9f433;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg)scale(1);
  }
  50% {
    transform: rotate(-15deg)scale(1.2);
  }
  100% {
    transform: rotate(0deg)scale(1);
  }
}